// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-innorug-js": () => import("./../../../src/pages/products/innorug.js" /* webpackChunkName: "component---src-pages-products-innorug-js" */),
  "component---src-pages-products-innosales-js": () => import("./../../../src/pages/products/innosales.js" /* webpackChunkName: "component---src-pages-products-innosales-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-marketplacehub-js": () => import("./../../../src/pages/products/marketplacehub.js" /* webpackChunkName: "component---src-pages-products-marketplacehub-js" */),
  "component---src-pages-products-pom-js": () => import("./../../../src/pages/products/pom.js" /* webpackChunkName: "component---src-pages-products-pom-js" */),
  "component---src-pages-products-tms-js": () => import("./../../../src/pages/products/tms.js" /* webpackChunkName: "component---src-pages-products-tms-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-templates-blog-details-templates-js": () => import("./../../../src/templates/blog-details-templates.js" /* webpackChunkName: "component---src-templates-blog-details-templates-js" */),
  "component---src-templates-job-details-template-js": () => import("./../../../src/templates/job-details-template.js" /* webpackChunkName: "component---src-templates-job-details-template-js" */),
  "component---src-templates-service-details-template-js": () => import("./../../../src/templates/service-details-template.js" /* webpackChunkName: "component---src-templates-service-details-template-js" */)
}

